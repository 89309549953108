import * as Yup from 'yup'
import { ErrorMessage } from './messages'
import { EMAIL_NUMBER } from './constantregex'

export let FreeTrialFormValidationSchema =Yup.object().shape({
    name:Yup.string().required(ErrorMessage.NameRequired),
    email:Yup.string().required(ErrorMessage.emailRequired).matches(EMAIL_NUMBER, ErrorMessage.emailValid, ErrorMessage.emailValid),
    companyName:Yup.string().required(ErrorMessage?.companyNameRequired)
})

export let SubscribeEmailValidation = Yup.object({
    email:Yup.string().required(ErrorMessage.emailRequired).matches(EMAIL_NUMBER, ErrorMessage.emailValid, ErrorMessage.emailValid)
})

