// App.js
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/scss/style.scss';
import Header from './view/common/Header';
import Footer from './view/common/Footer';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './view/Home/Home';
import Customer from './view/customer/Customer';
import Research from './view/research/Research';
import Blog from './view/blog/Blog';
import News from './view/news/News';
import NewsDetail from './view/news/NewsDetail';
import About from './view/about/About';
import Common from './view/Common';
import ContactUs from './view/contactUs/ContactUs';
import TermsAndConditions from './view/terms-conditions/TermsAndConditions';
import PrivacyPolicy from './view/privacy-policy/privacyPolicy';
import ScrollToTop from './ScrollToTop';

function FlyerPage() {
  return (
    <div>
      <img src={`${process.env.PUBLIC_URL}/image/conference.png`} alt="Flyer" style={{ width: '100%' }} />
    </div>
  );
}

function App() {
  const location = useLocation();
  const isFlyerPage = location.pathname === '/MAXX/flyer';

  return (
    <div className="App">
      <ScrollToTop />
      {isFlyerPage ? (
        <FlyerPage />
      ) : (
        <>
          <Common />
          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/contact' element={<ContactUs />} />
            <Route path='/customer' element={<Customer />} />
            <Route path='/research' element={<Research />} />
            <Route path='/blog' element={<Blog />} />
            <Route path='/news' element={<News />} />
            <Route path='/news/:id' element={<NewsDetail />} /> {/* New Route */}
            <Route path='/about' element={<About />} />
            <Route path='/terms-conditions' element={<TermsAndConditions />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path="/MAXX/flyer" element={<FlyerPage />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
