function TermsAndConditions() {

    return (
        <section className="terms-and-conditions">
            <div className="blog-slider-section">
                <div className="theme-title text-center">
                    <h2><span> Terms of Use</span></h2>
                </div>
            </div>
            <div className="container">
                <div className="terms-body">
                    <p className="italic">Last updated: July 15, 2024</p>
                    <p>Tursio Inc.("Tursio", “we,” or “us” ) is a generative AI company that solves critical
                        barriers for businesses adopting generative AI, including hallucination, privacy, cloud-scale,
                        and overall productivity. The following terms and conditions (the “Terms of Use”) govern your
                        access to and use of our website (the “Website”) and any other products or services provided by
                        Tursio through the Website. Before accessing and using our Website, please read these Terms
                        of Use carefully.By using the Website, you accept and agree to be bound and abide by these Terms
                        of Use.</p>

                    <p>If you access or use the Website in any way, you represent and agree that you: (i) have read and
                        understand these Terms of Use; (ii) have the authority to bind yourself and any entity you
                        represent; and (iii) agree to the terms of these Terms of Use. If you do not agree to the terms
                        of these Terms of Use, you may not access or use the Website for any purpose.</p>

                    <p className="mb-4" >These Terms of Use contains sections entitled “Disclaimer of Warranties” and “Limitations of
                        Liability.” Please read them carefully, as they limit Tursio's liability.</p>

                    <ol className="terms-list">
                        <li className="mb-4">
                            <h4> Changes to these Terms of Use and the Website</h4>
                            <p>Tursio reserves the right to make modifications to these Terms of Use at any time and
                                for
                                any
                                reason. Please check these Terms of Use periodically to ensure you are aware of any
                                modifications made by Tursio. Your continued use of the Website after any change to
                                these
                                terms constitutes Your agreement to those new or changed terms. Tursio may, at any
                                time
                                and
                                in its sole discretion, without prior notice, and without any liability to any User,
                                modify
                                or
                                discontinue any portion of the Website, either temporarily or permanently.</p></li>
                        <li className="mb-4">
                            <h4> Account Registration</h4>
                            <p>To access certain services offered via the Website, users must create
                                an account with a
                                unique username and password for use as login credentials (each, a “User ID”). You must
                                keep
                                your User ID confidential and secure. Do not disclose or share your User ID. You are
                                responsible for all acts, omissions and content carried out under your User ID, and
                                Tursio will consider all acts, omissions and content on the Website under your User
                                ID as
                                authorized by you. If you have any reason to believe that your User ID has been
                                compromised,
                                please contact Tursio immediately.</p></li>
                        <li className="mb-4">
                            <h4> Accessing the Website and Account Security</h4>
                            <p>We reserve the right to withdraw or amend this Website, and any service or material we
                                provide on the Website, in our sole discretion without notice. We will not be liable if
                                for
                                any reason all or any part of the Website is unavailable at any time or for any period.
                                From
                                time to time, we may restrict access to some parts of the Website, or the entire
                                Website, to
                                users, including registered users. You are responsible for both making all arrangements
                                necessary for you to have access to the Website and ensuring that all persons who access
                                the
                                Website through your internet connection are aware of these Terms of Use and comply with
                                them.</p>
                            <p>To access the Website or some of the resources it offers, you may be asked to provide
                                certain
                                registration details or other information. It is a condition of your use of the Website
                                that
                                all the information you provide on the Website is correct, current, and complete.</p>
                            <p>We have the right to disable any user name, password, or other identifier, whether chosen
                                by
                                you or provided by us, at any time in our sole discretion for any or no reason,
                                including
                                if, in our opinion, you have violated any provision of this Agreement.</p>
                        </li>
                        <li className="mb-4">
                            <h4> Intellectual Property Rights</h4>
                            <p>The Website and its entire contents, features, and functionality (including but not
                                limited
                                to all information, software, text, displays, images, video, and audio, and the design,
                                selection, and arrangement thereof) are owned by Tursio, its licensors, or other
                                providers of such material and are protected by United States and international
                                copyright,
                                trademark, patent, trade secret, and other intellectual property or proprietary rights
                                laws.</p>
                            <p>These Terms of Use permit you to use the Website for your personal, non-commercial use
                                only.
                                You must not reproduce, distribute, modify, create derivative works of, publicly
                                display,
                                publicly perform, republish, download, store, or transmit any of the material on our
                                Website.</p>
                            <ul className="mb-4" >
                                <label>You must not:</label>
                                <li><p> Modify copies of any materials from this site.</p></li>
                                <li><p> Use any illustrations, photographs, video or audio sequences, or any graphics
                                    separately
                                    from the accompanying text.</p>
                                </li>
                                <li><p> Delete or alter any copyright, trademark, or other proprietary rights notices from
                                    copies of materials from this site.</p>
                                </li>
                                <li><p> Access or use for any commercial purposes any part of the Website or any services or
                                    materials available through the Website.</p>
                                </li>
                            </ul>

                            <p>If you print, copy, modify, download, or otherwise use or provide any other person with
                                access to any part of the Website in breach of the Terms of Use, your right to use the
                                Website will stop immediately and you must, at our option, return or destroy any copies
                                of
                                the materials you have made. No right, title, or interest in or to the Website or any
                                content on the Website is transferred to you, and all rights not expressly granted are
                                reserved by Tursio. Any use of the Website not expressly permitted by these Terms of
                                Use
                                is a breach of these Terms of Use and may violate copyright, trademark, and other
                                laws.</p></li>
                        <li className="mb-4">
                            <h4> Prohibited Uses of the Website</h4>
                            <ul className="mb-4">
                                <label>You may use the Website only for lawful purposes and in accordance with these
                                    Terms
                                    of Use. You agree not to use the Website:</label>
                                <li><p> In any way that violates any applicable federal, state, local, or international
                                    law
                                    or
                                    regulation (including, without limitation, any laws regarding the export of data or
                                    software to and from the US or other countries).</p>
                                </li>
                                <li><p> For the purpose of exploiting, harming, or attempting to exploit or harm minors
                                    in
                                    any
                                    way by exposing them to inappropriate content, asking for personally identifiable
                                    information, or otherwise.</p>
                                </li>
                                <li><p> To send, knowingly receive, upload, download, use, or re-use any material that
                                    does
                                    not
                                    comply with these Terms of Use.</p>
                                </li>
                                <li><p> To transmit, or procure the sending of, any advertising or promotional material,
                                    including any "junk mail," "chain letter," "spam," or any other similar
                                    solicitation.</p>
                                </li>
                                <li><p> To impersonate or attempt to impersonate Tursio, a Tursio employee,
                                    another
                                    user,
                                    or any other person or entity (including, without limitation, by using email
                                    addresses
                                    or screen names associated with any of the foregoing).
                                </p>
                                </li>
                                <li><p> To engage in any other conduct that restricts or inhibits anyone's use or
                                    enjoyment
                                    of
                                    the Website, or which, as determined by us, may harm Tursio or users of the
                                    Website,
                                    or expose them to liability.</p>
                                </li>


                            </ul>
                            <ul className="mb-4">
                                <label>Additionally, you agree not to:</label>
                                <li><p> Use the Website in any manner that could disable, overburden, damage, or impair
                                    the
                                    site or interfere with any other party's use of the Website, including their
                                    ability
                                    to engage in real time activities through the Website.</p>
                                </li>
                                <li><p> Use any robot, spider, or other automatic device, process, or means to access
                                    the
                                    Website for any purpose, including monitoring or copying any of the material on
                                    the
                                    Website.</p>
                                </li>
                                <li><p> Use any manual process to monitor or copy any of the material on the Website, or
                                    for
                                    any other purpose not expressly authorized in these Terms of Use, without our
                                    prior
                                    written consent.</p>
                                </li>
                                <li><p> Use any device, software, or routine that interferes with the proper working of
                                    the
                                    Website. Introduce any viruses, Trojan horses, worms, logic bombs, or other
                                    material
                                    that is malicious or technologically harmful.</p>
                                </li>
                                <li><p> Attempt to gain unauthorized access to, interfere with, damage, or disrupt any
                                    parts
                                    of the Website, the server on which the Website is stored, or any server,
                                    computer,
                                    or database connected to the Website.</p>
                                </li>
                                <li><p> Attack the Website via a denial-of-service attack or a distributed
                                    denial-of-service
                                    attack.</p>
                                </li>
                                <li><p> Otherwise attempt to interfere with the proper working of the Website.</p>
                                </li>
                            </ul>
                        </li>
                        <li className="mb-4">
                            <h4> Reliance on Information Posted </h4>
                            <p>The information presented on or through the Website is made available solely for general
                                information purposes. We do not warrant the accuracy, completeness, or usefulness of
                                this
                                information. Any reliance you place on such information is strictly at your own risk. We
                                disclaim all liability and responsibility arising from any reliance placed on such
                                materials
                                by you or any other visitor to the Website, or by anyone who may be informed of any of
                                its
                                contents.</p>
                            <p>This Website may include content provided by third parties, including materials provided
                                by
                                other users, bloggers, and third-party licensors, syndicators, aggregators, and/or
                                reporting
                                services. All statements and/or opinions expressed in these materials, and all articles
                                and
                                responses to questions and other content, other than the content provided by Tursio,
                                are
                                solely the opinions and the responsibility of the person or entity providing those
                                materials. These materials do not necessarily reflect the opinion of Tursio. We are
                                not
                                responsible, or liable to you or any third party, for the content or accuracy of any
                                materials provided by any third parties.</p></li>
                        <li className="mb-4"><h4> Disclaimer of Warranties</h4>
                            <p>You understand that we cannot and do not guarantee or warrant that files available for
                                downloading from the internet or the Website will be free of viruses or other
                                destructive
                                code. You are responsible for implementing sufficient procedures and checkpoints to
                                satisfy
                                your particular requirements for anti-virus protection and accuracy of data input and
                                output, and for maintaining a means external to our site for any reconstruction of any
                                lost
                                data.</p>
                            <p className="uppercase">TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR
                                ANY
                                LOSS OR
                                DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
                                TECHNOLOGICALLY
                                HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR
                                OTHER
                                PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED
                                THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY
                                WEBSITE
                                LINKED TO IT.</p>
                            <p className="uppercase">TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR
                                ANY
                                LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
                                TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER
                                PROGRAMS,
                                DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR
                                ITEMS
                                OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON
                                ANY
                                WEBSITE LINKED TO IT.</p>
                            <p className="uppercase">YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS
                                OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY
                                SERVICES
                                OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
                                BASIS,
                                WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER TURSIO NOR ANY
                                PERSON ASSOCIATED WITH TURSIO MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO
                                THE
                                COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE.
                                WITHOUT LIMITING THE FOREGOING, NEITHER TURSIO NOR ANYONE ASSOCIATED WITH TURSIO
                                REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED
                                THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
                                DEFECTS
                                WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                                VIRUSES
                                OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED
                                THROUGH
                                THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
                            <p className="uppercase">TO THE FULLEST EXTENT PROVIDED BY LAW, TURSIO HEREBY DISCLAIMS
                                ALL
                                WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING
                                BUT
                                NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
                                PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
                                OR
                                LIMITED UNDER APPLICABLE LAW.</p>
                        </li>
                        <li className="mb-4"><h4> Limitation on Liability</h4>
                            <p className="uppercase">TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL TURSIO,
                                ITS
                                AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR
                                DIRECTORS
                                BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
                                CONNECTION
                                WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT
                                ON
                                THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                                CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN
                                AND
                                SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                                ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
                                TORT
                                (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. THE
                                FOREGOING
                                DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
                                LAW.</p>
                        </li>
                        <li className="mb-4"> <h4> Indemnification </h4>
                            <p>You agree to defend, indemnify, and hold harmless Tursio, its affiliates, licensors,
                                and
                                service providers, and its and their respective officers, directors, employees,
                                contractors,
                                agents, licensors, suppliers, successors, and assigns from and against any claims,
                                liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including
                                reasonable attorneys' fees) arising out of or relating to your violation of these Terms
                                of
                                Use or your use of the Website, including, but not limited to, your User Contributions,
                                any
                                use of the Website's content, services, and products other than as expressly authorized
                                in
                                these Terms of Use, or your use of any information obtained from the Website.</p>
                        </li>
                        <li className="mb-4"> <h4> Governing Law and Jurisdiction </h4>

                            <p>All matters relating to the Website and these Terms of Use, and any dispute or claim
                                arising
                                therefrom or related thereto (in each case, including non-contractual disputes or
                                claims),
                                shall be governed by and construed in accordance with the internal laws of the State of
                                Delaware without giving effect to any choice or conflict of law provision or rule
                                (whether
                                of the State of Delaware or any other jurisdiction).</p>
                            <p>Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use
                                or
                                the Website shall be instituted exclusively in the federal courts of the United States
                                or
                                the courts of the State of Washington, although we retain the right to bring any suit,
                                action, or proceeding against you for breach of these Terms of Use in your country of
                                residence or any other relevant country. You waive any and all objections to the
                                exercise of
                                jurisdiction over you by such courts and to venue in such courts.</p>
                        </li>
                        <li className="mb-4"> <h4> Arbitration </h4>
                            <p>At Tursio's sole discretion, it may require you to submit any disputes arising from
                                these
                                Terms of Use or use of the Website, including disputes arising from or concerning their
                                interpretation, violation, invalidity, non-performance, or termination, to final and
                                binding
                                arbitration under the Rules of Arbitration of the American Arbitration Association
                                applying
                                Delaware law.</p>
                        </li>
                        <li className="mb-4"> <h4> Waiver and Severability </h4>
                            <p>No waiver by Tursio of any term or condition set out in these Terms of Use shall be
                                deemed
                                a further or continuing waiver of such term or condition or a waiver of any other term
                                or
                                condition, and any failure of Tursio to assert a right or provision under these Terms
                                of
                                Use shall not constitute a waiver of such right or provision.</p>
                            <p>If any provision of these Terms of Use is held by a court or other tribunal of competent
                                jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision
                                shall
                                be eliminated or limited to the minimum extent such that the remaining provisions of the
                                Terms of Use will continue in full force and effect.</p>
                        </li>
                        <li className="mb-4"> <h4> Entire Agreement </h4>
                            <p>The Terms of Use constitute the sole and entire agreement between you and Tursio
                                regarding
                                the Website and supersede all prior and contemporaneous understandings, agreements,
                                representations, and warranties, both written and oral, regarding the Website.</p>
                        </li>
                        <li className="mb-4"><h4> Your Comments and Concerns </h4>
                            <p>All feedback, comments, requests for technical support, and other communications relating
                                to
                                the Website should be directed to: contact@tursio.ai</p>
                        </li>
                    </ol>

                </div>
            </div>
        </section>
    )

}

export default TermsAndConditions
