import {useEffect} from "react";
import Swiper from "swiper";
import 'swiper/swiper-bundle.css';
import {Mousewheel, Autoplay, Navigation} from 'swiper/modules';
import anime from 'animejs/lib/anime.es.js';


const Common = () => {

    useEffect(() => {
        // Initialize Swipers and event listeners on component mount

        // Swiper for companyLogo
        var swiperCompanyLogo = new Swiper(".companyLogo", {
            modules: [Autoplay],
            slidesPerView: 5,
            autoplay: {
                delay: 1000,
                disableOnInteraction: false,
            },
            spaceBetween: 30,
            breakpoints: {
                320: {slidesPerView: 3, spaceBetween: 20},
                575: {slidesPerView: 3, spaceBetween: 30},
                1024: {slidesPerView: 3, spaceBetween: 10},
                1440: {slidesPerView: 5, spaceBetween: 10},
            },
        });

        // Swiper for customerFeedback
        var swiperCustomerFeedback = new Swiper(".customerFeedback", {
            slidesPerView: 3,
            spaceBetween: 30,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                320: {slidesPerView: 1, spaceBetween: 20},
                768: {slidesPerView: 2, spaceBetween: 30},
                1024: {slidesPerView: 3, spaceBetween: 10},
            },
        });

        // Swiper for caseStudies
        var swiperCaseStudies = new Swiper(".caseStudies", {
            modules: [Navigation],
            slidesPerView: 2,
            centeredSlides: true,
            grabCursor: true,
            navigation: {
                nextEl: ".swiper-button-next",
            },
            breakpoints: {
                320: {slidesPerView: 1},
                575: {slidesPerView: 1},
                1024: {slidesPerView: 2},
                1440: {slidesPerView: 3},
                1875: {slidesPerView: 4},
            },
        });

        // Swiper for customerHomeSlider
        var swiperCustomerHomeSlider = new Swiper(".customerHomeSlider", {
            modules: [Mousewheel],
            slidesPerView: 2,
            centeredSlides: true,
            grabCursor: true,
            mousewheel: true,
            breakpoints: {
                320: {direction: "horizontal", slidesPerView: 1, loop: false},
                425: {direction: "horizontal", slidesPerView: 1, loop: false},
                550: {direction: "horizontal", slidesPerView: 1, loop: false},
                768: {direction: "horizontal", slidesPerView: 2, loop: false},
                991: {direction: "horizontal", slidesPerView: 2, loop: false},
                992: {direction: "vertical", loop: false},
            },
        });

        // Swiper for blogCard
        var swiperBlogCard = new Swiper(".blogCard", {
            pagination: {
                el: ".swiper-pagination",
            },
            slidesPerView: 5,
            spaceBetween: 30,
            breakpoints: {
                320: {slidesPerView: 1, spaceBetween: 20},
                991: {slidesPerView: 1, spaceBetween: 30},
                1024: {slidesPerView: 2, spaceBetween: 10},
                1440: {slidesPerView: 2, spaceBetween: 10},
            },
        });

        // Scroll event listener
        const handleScroll = () => {
            let scroll = window?.scrollY;
            let header = document.querySelector(".theme-header");
            if (scroll > 10) {
                header.classList.add("shadow");
            } else {
                header.classList.remove("shadow");
            }

            // Custom CSS variable for scroll

        };

        window.addEventListener("scroll", handleScroll);

        window.addEventListener("scroll", (event) => {
            let scroll = window.scrollY;
            if (scroll > 10) {
                document.querySelector(".theme-header").classList.add("shadow");
            } else {
                document.querySelector(".theme-header").classList.remove("shadow");
            }
        });


        // window.addEventListener(
        //     "scroll",
        //     () => {
        //         // console.log(document.getElementById('enterprises-img-two').getBoundingClientRect().top);
        //         if (document.getElementById('enterprises-img-two')) {
        //             if (document.getElementById('enterprises-img-two')?.getBoundingClientRect()?.top > 100) {
        //                 document.body.style.setProperty(
        //                     "--homePageCube",
        //                     window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
        //                 );
        //             }
        //         }
        //     },
        //     false
        // );


        window.addEventListener(
            "scroll",
            () => {
                // console.log(document.getElementById('Database-path-two')?.getBoundingClientRect().top);
                if (document.getElementById('Database-path-two')) {
                    if (document.getElementById('Database-path-two')?.getBoundingClientRect()?.top > 100) {
                        document.body.style.setProperty(
                            "--dataSectionCube",
                            window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
                        );
                    }
                }
            },
            false
        );


        window.addEventListener(
            "scroll",
            () => {

                if (document.getElementById('customer-one')) {
                    if (document.getElementById('customer-one')?.getBoundingClientRect()?.top > 100) {
                        document.body.style.setProperty(
                            "--customerCube",
                            window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
                        );
                    }
                }
            },
            false
        );

        function isElementInViewport(el, inCB, outCB) {
            function handleIntersect(entries, observer) {
                var entry = entries[0];
                if (entry.isIntersecting) {
                    if (inCB) inCB(el, entry);
                } else {
                    if (outCB) outCB(el, entry);
                }
            }

            var observer = new IntersectionObserver(handleIntersect);
            observer.observe(el);
        }

        const hexagonDatabaseWorkloadContainer = document.querySelector('#DatabaseWorkload');
        var controlHexagonDatabaseWorkload = false;
        const timeControlHexagonDatabaseWorkload = anime.timeline({
            easing: 'linear',
            autoplay: false
        })
            .add({
                targets: '#database-path-one',
                keyframes: [

                    {translateY: '-81%', duration: 900},

                ],
                duration: 900,
            }, -100)
            .add({
                targets: '#database-path-three',
                keyframes: [

                    {translateY: '48%', duration: 900},

                ],
                duration: 900
            }, -100);


        function moveControlDatabaseWorkloadAnimation() {
            var rect = hexagonDatabaseWorkloadContainer.getBoundingClientRect();
            var top = rect.top;
            var windowHeight = window.innerHeight;
            var scrolled = (top - windowHeight) * -1;
            console.log('DatabaseWorkloadScrolled', scrolled)
            timeControlHexagonDatabaseWorkload.seek((scrolled));


            if (controlHexagonDatabaseWorkload) requestAnimationFrame(moveControlDatabaseWorkloadAnimation);
        }
        if (hexagonDatabaseWorkloadContainer) {
            isElementInViewport(hexagonDatabaseWorkloadContainer, function (el, entry) {
                controlHexagonDatabaseWorkload = true;
                requestAnimationFrame(moveControlDatabaseWorkloadAnimation);
            }, function (el, entry) {
                controlHexagonDatabaseWorkload = false;
            });
        }

        const hexagonEnterPriceContainer = document.querySelector('#enterprises-img-two');
        var controlHexagonEnterPrice = false;
        const timeControlHexagonEnterPrice = anime.timeline({
            easing: 'linear',
            autoplay: false
        })
            .add({
                targets: '#enterprises-img-two',
                keyframes: [

                    {translateY: '45%', rotate: '15deg', duration: 900},

                ],
                duration: 900,
            }, -100)
            .add({
                targets: '#enterprises-img-three',
                keyframes: [

                    {translateY: '40%', translateX: '21%', rotate: '45deg', duration: 900},

                ],
                duration: 900
            }, -100)
            .add({
                targets: '#enterprises-img-five',
                keyframes: [
                    {translateY: '140%', translateX: '-207%', rotate: '102deg', duration: 900},
                ],
                duration: 900
            }, -100).add({
                targets: '#enterprises-img-four',
                keyframes: [

                    {translateY: '59%', translateX: '-19%', rotate: '135deg', duration: 900},

                ],
                duration: 900
            }, -100);


        function moveControlEnterPriceAnimation() {
            var rect = hexagonEnterPriceContainer.getBoundingClientRect();
            var top = rect.top;
            var windowHeight = window.innerHeight;
            var scrolled = (top - windowHeight) * -1;
            console.log('EnterPriceScrolled', scrolled)
            timeControlHexagonEnterPrice.seek((scrolled));


            if (controlHexagonEnterPrice) requestAnimationFrame(moveControlEnterPriceAnimation);
        }

        // debugger;
        if (hexagonEnterPriceContainer) {
            isElementInViewport(hexagonEnterPriceContainer, function (el, entry) {
                controlHexagonEnterPrice = true;
                requestAnimationFrame(moveControlEnterPriceAnimation);
            }, function (el, entry) {
                controlHexagonEnterPrice = false;
            });
        }


        const hexagonJoinTursioContainer = document.querySelector('.join-tursio-section');
        var controlHexagonJoinTursio = false;
        const timeControlHexagonJoinTursio = anime.timeline({
            easing: 'linear',
            autoplay: false
        })
            .add({
                targets: '.join-cube-one',
                keyframes: [

                    {translateY: '35%', duration: 900},

                ],
                duration: 900,
            }, -100)
            .add({
                targets: '.join-cube-tow',
                keyframes: [

                    {translateY: '-119%', duration: 900},

                ],
                duration: 900
            }, -100)


        function moveControlJoinTursioAnimation() {
            var rect = hexagonJoinTursioContainer.getBoundingClientRect();
            var top = rect.top;
            var windowHeight = window.innerHeight;
            var scrolled = (top - windowHeight) * -1;
            console.log('EnterPriceScrolled', scrolled)
            timeControlHexagonJoinTursio.seek((scrolled));


            if (controlHexagonJoinTursio) requestAnimationFrame(moveControlJoinTursioAnimation);
        }

        // debugger;
        if (hexagonJoinTursioContainer) {
            isElementInViewport(hexagonJoinTursioContainer, function (el, entry) {
                controlHexagonJoinTursio = true;
                requestAnimationFrame(moveControlJoinTursioAnimation);
            }, function (el, entry) {
                controlHexagonJoinTursio = false;
            });
        }


        const hexagonContainer = document.querySelector('.unlimited-cube-container');

        let controlAnimationCanMove = false;

        const timeControlHexagonWhiteBlue = anime.timeline({
            easing: 'linear',
            autoplay: false
        })
            .add({
                targets: '#hexagonWhite',
                keyframes: [

                    {translateY: '-3.438rem', duration: 1500},

                ],
                duration: 1500,
            }, 100)
            .add({
                targets: '#hexagonBlue',
                keyframes: [

                    {translateY: '3.438rem', duration: 1500},

                ],
                duration: 1500
            }, 100);


        const timeControlHexagonAbout = anime.timeline({
            easing: 'linear',
            autoplay: false
        })
            .add({
                targets: '.about-cube-one',
                keyframes: [

                    {translateY: '50%', duration: 800},


                ],
                duration: 800,
            }, -100)
            .add({
                targets: '.about-cube-tow',
                keyframes: [
                    {translateY: '48%', translateX: '105%', duration: 800}
                ],
                duration: 800,

            }, -100);
        ;


        window.addEventListener(
            "scroll",
            () => {

                if (document.querySelector('.about-cube-three')) {
                    if (document.querySelector('.about-cube-three')?.getBoundingClientRect()?.top > 30) {
                        var rect = document.querySelector('.about-slide-cube').getBoundingClientRect();
                        var top = rect.top;
                        var windowHeight = window.innerHeight;
                        var scrolled = (top - windowHeight) * -1;
                        console.log("top", top)
                        console.log("seek", (top - 30) * 11)
                        timeControlHexagonAbout.seek(800 - (top - 30) * 13);
                    }
                }
            },
            false
        );


        function moveControlHomeHexagonAnimation() {
            var rect = hexagonContainer.getBoundingClientRect();
            var top = rect.top;
            var windowHeight = window.innerHeight;
            var scrolled = (top - windowHeight) * -1;
            timeControlHexagonWhiteBlue.seek((scrolled * 3) - 500);


            if (controlAnimationCanMove) requestAnimationFrame(moveControlHomeHexagonAnimation);
        }


        if (hexagonContainer) {
            isElementInViewport(hexagonContainer, function (el, entry) {
                controlAnimationCanMove = true;
                requestAnimationFrame(moveControlHomeHexagonAnimation);
            }, function (el, entry) {
                controlAnimationCanMove = false;
            });
        }


        return () => {
            window.removeEventListener("scroll", handleScroll);
            swiperCompanyLogo.destroy();
            swiperCustomerFeedback.destroy();
            swiperCaseStudies.destroy();
            swiperCustomerHomeSlider.destroy();
            swiperBlogCard.destroy();
        };

    }, []); // Empty dependency array ensures useEffect runs only on component mount

    // Scroll to top function
    const backToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };


};

export default Common;
