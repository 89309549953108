import contactUsData from '../../assets/json/contactUsData.json'
import { FreeTrialFormValidationSchema } from '../../constants/validation';
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {PopupModal} from "react-calendly";
import React, {useState} from 'react'

function ContactUs() {
    const [isOpen, setisOpen] = useState(false);
    const [prefill, setprefill] = useState({});
  const initialValues = {
    name: '',
    email: '',
    companyName: '',
}

const handleSubmit = (values) => {
    // window.location = `mailto:rony@tursio.ai?subject=I want to use your service&body=${JSON.stringify(values)}`;
    values = {
        ...values,
        name: `${values.name}`,
        customAnswers: {a1: JSON.stringify(values, null, 4)}
    };
    setprefill(values);

    setisOpen(true);
}

  return (
    <div>
      <section className="contact-form">
    <div className="container">
        <div className="theme-title contact-title-header">
            <h2><span>Contact</span></h2>
        </div>

        <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="shape-with-title">
                    <img className="shape-contact-image" src={`${process.env.PUBLIC_URL}/image/${contactUsData?.BannerData?.imageFileName}.svg`} alt="" />

                    <div className="theme-title mt-3">
                        <h2>{contactUsData?.BannerData?.title} <br /> <span> {contactUsData?.BannerData?.subTitle}</span></h2>
                        <p>{contactUsData?.BannerData?.content}</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="form">
                    <h4 className="contact-form-title">{contactUsData?.BannerData?.FormTitle}</h4>

                    <Formik
                                    initialValues={initialValues}
                                    validationSchema={FreeTrialFormValidationSchema}
                                    onSubmit={handleSubmit}>
                                       <Form id="itemForm">
                    <div className="contact-input">
                    <label htmlFor='name'>Name</label>
                    <Field type="text" name="name" id="firstName"
                                                           placeholder=""/>
                                                    <span className='text-danger'><ErrorMessage
                                                        name="name"/></span>
                    </div>
                    <div className="contact-input">
                    <label htmlFor='companyName'>Company Name</label>
                                                    <Field type="text" placeholder="" name="companyName"
                                                           id="companyName"/>
                                                    <span className='text-danger'>   <ErrorMessage name="companyName"/> </span>
                    </div>
                    <div className="contact-input">
                    <label htmlFor='email'>Email</label>
                                                    <Field type="email" placeholder="" name="email"
                                                           id="email"/>
                                                    <span className='text-danger'>
                                                    <ErrorMessage  name="email"/> </span>
                    </div>
                    <button type="submit" className="btn btn-primary mt-4">Submit</button>
                    </Form>
                    </Formik>
                </div>
            </div>

            <span className="border-bottom border-spacing"></span>
        </div>
    </div>
</section>

{/* <section className="faq-section">
        <div className="container">

          <div className="theme-title text-center">
            <h2>
              {contactUsData?.FAQ?.title}<span>’</span>{contactUsData?.FAQ?.subTitle}
            </h2>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8 col-md-12">
              {
                contactUsData?.FAQ?.FaqContent?.map((ele,ind) =>{
                 return (
                  <div className="accordion accordion-flush" id={ele.id} key={ind}>
                  <div className="accordion-item">
                      <h2 className="accordion-header" id={ele?.headingId}>
                          <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                  data-bs-target={`#${ele?.buttonId}`} aria-expanded="false"
                                  aria-controls={`${ele?.buttonId}`}>
                              {ele?.title}
                              <div className="add-remove-icon">
                                  <img className="add-icon" src={`${process.env.PUBLIC_URL}/image/${ele?.addIconFileName}.svg`} alt="" />
                                  <img className="remove-icon" src={`${process.env.PUBLIC_URL}/image/${ele?.removeIconFileName}.svg`} alt="" />
                              </div>
                          </button>
                      </h2>
                      <div id={`${ele?.buttonId}`} className="accordion-collapse collapse show"
                           aria-labelledby={`#${ele?.headingId}`} data-bs-parent={`#${ele?.id}`}>
                          <div className="accordion-body">
                              <p>{ele?.content}</p>
                          </div>
                      </div>
                  </div>
              </div>
                 )
                })
              }

            </div>
        </div>

        </div>
      </section> */}

      <section className="questions-section margin-top">
        <div className="container">
          <div className="questions-card">
            <div className="row justify-content-between align-items-center position-relative">
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="content">
                  <div className="me-4">
                    <img
                      src={`${process.env.PUBLIC_URL}/image/${contactUsData?.UnlimitedUser?.imageFileName}.svg`}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="theme-title">
                      <h2 className="text-white">
                        <span> {contactUsData?.UnlimitedUser?.title}</span>
                        <br />
                        {contactUsData?.UnlimitedUser?.subTitle}
                      </h2>
                      <p className="mb-0 mt-2 text-white">
                        {contactUsData?.UnlimitedUser?.content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="questions-btn">
                  <button type="button" className="btn btn-outline-light ms-3">
                    {contactUsData?.UnlimitedUser?.requestDemoBtn}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        <PopupModal
            url="https://calendly.com/rony-8gbr/30min"
            prefill={prefill}
            onModalClose={() => setisOpen(false)}
            open={isOpen}
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root")}
        />
    </div>
  );
}

export default ContactUs;
