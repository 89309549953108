import { useNavigate } from "react-router-dom";
import customerData from "../../assets/json/customerData.json";
import Common from "../Common";

function Customer() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/contact');
  };
  return (
    <div className="customer-page">
      <Common />
      <section className="customer-slider">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
              {/* <section className="breadcrumb-menu">
                <div className="mb-3">
                  <ul className="menu">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li> {">"} </li>
                    <li className="active">Customer</li>
                  </ul>
                </div>
              </section> */}
              <div className="header-theme-title h-100">
                <h1>
                  {customerData?.CustomerBanner?.title}{" "}
                  <span>{customerData?.CustomerBanner?.subTitle}</span>
                </h1>
                <p>{customerData?.CustomerBanner?.content}</p>
                <div className="d-inline-block w-100 mt-4">
                  <button type="button" className="btn btn-primary me-2" onClick={handleClick}>
                    {customerData?.CustomerBanner?.requestDemoBtn}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="swiper customerHomeSlider">
                <div className="swiper-wrapper">
                  {customerData?.CustomerBanner?.CustomerCard?.map(
                    (ele, ind) => {
                      return (
                        <div className="swiper-slide" key={ind}>
                          <div className="customer-feedback-card">
                            <img
                              src={`${process.env.PUBLIC_URL}/image/${ele?.startImageFileName}.svg`}
                              alt=""
                            />
                            <span className="feedback-title">{ele?.title}</span>
                            <p>{ele?.subTitle}</p>
                            <div className="customer-user">
                              <div className="me-3">
                                <img
                                  src={`${process.env.PUBLIC_URL}/image/${ele?.profileImageFileName}.jpg`}
                                  alt=""
                                />
                              </div>
                              <div>
                                <h4>{ele?.CustomerName}</h4>
                                <span>{ele?.CustomerContant}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="company-logo customer-company-logo">
        <div className="container">
          <div className="swiper companyLogo">
            <div className="swiper-wrapper">
              {customerData?.logoSwiper.map((logo, ind) => {
                return (
                  <div className="swiper-slide" key={ind}>
                    <img
                      src={`${process.env.PUBLIC_URL}/image/${logo.logourl}.png`}
                      alt=""
                      key={ind}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <div className="case-studies-section" >
        <div className="container">
          <div className="row" id="casestudie">
            <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12">
              <div className="theme-title">
                <label>Case studies</label>
                <h2>
                  {customerData?.Casestudies?.title}{" "}
                  <span> {customerData?.Casestudies?.subTitle}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="case-studies-slider-body">
          <div className="swiper caseStudies">
            <div className="swiper-wrapper">
              {customerData?.Casestudies?.CustomerBannerSlider?.map(
                (ele, ind) => {
                  return (
                    <div className="swiper-slide" key={ind}>
                      <div className="case-studies-card">
                        <div className="left-content">
                          <img
                            className="logo"
                            src={`${process.env.PUBLIC_URL}/image/${ele?.logoFilName}.png`}
                            alt=""
                          />
                          <div className="content">
                            <span>{ele?.label}</span>
                            <h4>{ele?.title}</h4>
                            <p>{ele?.content}</p>
                          </div>
                        </div>
                        <div className="right-content">
                          <img
                            src={`${process.env.PUBLIC_URL}/image/${ele?.imageFileName}.jpg`}
                            alt=""
                          />
                        </div>

                        <div className="hover-container">
                          <div className="left-content">
                            <div className="d-flex justify-content-between align-items-center">
                              <img
                                className="logo"
                                src={`${process.env.PUBLIC_URL}/image/${ele?.logoFilName}.png`}
                                alt=""
                              />
                              <a href="#" className="download-icon">
                                Download
                                <img
                                  src={`${process.env.PUBLIC_URL}/image/${ele?.Download}.svg`}
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="content">
                              <span>{ele?.label}</span>
                              <h4>{ele?.title}</h4>
                              <p>{ele?.content}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <div className="swiper-button-next"></div>
          </div>
        </div>
      </div>

      <section className="customer-scenarios-section">
        <div className="container position-relative">
          <div className="row justify-content-between">
            <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 mb-4">
              <div className="theme-title">
                <h2>
                  {customerData?.CustomerScenarios?.title}{" "}
                  <span> {customerData?.CustomerScenarios?.subTitle}</span>
                </h2>
                <p>{customerData?.CustomerScenarios?.content}</p>
                <button type="button" className="btn btn-primary me-2" onClick={handleClick}>
                  {customerData?.CustomerScenarios?.requestDemoBtn}
                </button>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
              {customerData?.CustomerScenarios?.CustomerCard?.map(
                (ele, index) => {
                  return (
                    <div className="customer-card mb-3" key={index}>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h3>{ele?.title}</h3>
                        <img
                          src={`${process.env.PUBLIC_URL}/image/${ele?.imageFileName}.svg`}
                          alt=""
                        />
                      </div>
                      <p>{ele?.content}</p>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="questions-section">
        <div className="container">
          <div className="questions-card">
            <div className="row justify-content-between align-items-center position-relative">
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="content">
                  <div className="me-4">
                    <img
                      src={`${process.env.PUBLIC_URL}/image/${customerData?.UnlimitedUser?.imageFileName}.svg`}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="theme-title">
                      <h2 className="text-white">
                        <span> {customerData?.UnlimitedUser?.title}</span>
                        <br />
                        {customerData?.UnlimitedUser?.subTitle}
                      </h2>
                      <p className="mb-0 mt-2 text-white">
                        {customerData?.UnlimitedUser?.content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="questions-btn">
                  <button type="button" className="btn btn-outline-light ms-3" onClick={handleClick}>
                    {customerData?.UnlimitedUser?.requestDemoBtn}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default Customer;
