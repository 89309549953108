import React from "react";
import { Link } from "react-router-dom";
import newsData from "../../assets/json/news.json";
import "./news.css";

function News() {
  return (
    <div className="news-page">
      <div className="news-card-section">
        <div className="container">
          <div className="news-search-header">
            <div className="theme-title">
              <h3 className="title-center"><span>Press Releases</span></h3>
            </div>
          </div>
          <div className="row">
            {newsData.map((news, index) => (
              <div
                className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3"
                key={index}
              >
                <div className="news-card">
                  <h3>{news.title}</h3>
                  <p>By: {news.author}</p>
                  <p>{news.content.substring(0, 100)}...</p>
                  <Link to={`/news/${index}`}>
                    <button className="read-more-btn">
                      Read More
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
