import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PopupModal } from 'react-calendly';

function Footer() {
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [prefill, setPrefill] = useState({});
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const jumpToReleventDiv = (path, elementId) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      } else {
        scrollToTop();
      }
    }, 300);
  };

  const handleSubmit = () => {
    const values = {
      email: email,
      customAnswers: { a1: email }
    };
    setPrefill(values);
    setIsOpen(true);
  };

  return (
    <footer className="theme-footer">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="theme-title">
              <h2>
                <span>Sign up</span> to learn more
              </h2>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="email-input">
              <label htmlFor="footer-email">Email</label>
              <div className="footer-input">
                <input
                  type="text"
                  className="form-control"
                  id="footer-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className="btn arrow-btn" onClick={handleSubmit}>
                  <img src="image/input-right-arrow.svg" alt="Submit" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="top-scroll-section">
          <Link className="navbar-brand p-0" to="/">
            <img src="image/logo.png" alt="logo" />
          </Link>
          <span className="solid-border"></span>
          <button
            type="button"
            className="btn scroll-btn"
            id="btn-back-to-top"
            onClick={scrollToTop}
          >
            <img src="image/top-scroll.svg" alt="Scroll to top" />
            <span>Top</span>
          </button>
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <div className="footer-text-content">
              <p>
                Tursio offers a turnkey solution by transforming databases into
                generative AI machines, empowering enterprises to leverage AI
                without extra infrastructure or compromising security.
              </p>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="footer-link mobile-hide">
                  <label>Product</label>
                  <ul>
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={() => jumpToReleventDiv("/", "product-section")}
                      >
                        Approach
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={() => jumpToReleventDiv("/", "no-brainer-section")}
                      >
                        Values
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="footer-link mobile-hide">
                  <label>About</label>
                  <ul>
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={() => jumpToReleventDiv("/about", "story")}
                      >
                        Our Story
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={() => jumpToReleventDiv("/about", "leaders")}
                      >
                        Leadership
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="javascript:void(0)"
                        onClick={() => jumpToReleventDiv("/contact")}
                      >
                        Contact
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="footer-link mobile-hide">
                  <label>Resources</label>
                  <ul>
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={() => jumpToReleventDiv("/blog")}
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={() => jumpToReleventDiv("/news")}
                      >
                        News
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={() => jumpToReleventDiv("/research")}
                      >
                        Research
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="footer-link mobile-hide">
                  <label>Others</label>
                  <ul>
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={() => jumpToReleventDiv("/terms-conditions")}
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        onClick={() => jumpToReleventDiv("/privacy-policy")}
                      >
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-accordion">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <b> Product</b>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="footer-link">
                      <ul>
                        <li>
                          <a
                            href="javascript:void(0)"
                            onClick={() => jumpToReleventDiv("/", "product-section")}
                          >
                            Approach
                          </a>
                        </li>
                        <li> 
                          <a
                            href="javascript:void(0)"
                            onClick={() => jumpToReleventDiv("/", "no-brainer-section")}
                          >
                            Values
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="accordionTwo">
              <div className="accordion-item">
                <h2 className="accordion-header" id="footerHeadingTwo">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#footerCollapseTwo"
                    aria-expanded="true"
                    aria-controls="footerCollapseTwo"
                  >
                    <b>About</b>
                  </button>
                </h2>
                <div
                  id="footerCollapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="FooterHeadingTwo"
                  data-bs-parent="#accordionTwo"
                >
                  <div className="accordion-body">
                    <div className="footer-link">
                      <ul>
                        <li>
                          <a
                            href="javascript:void(0)"
                            onClick={() => jumpToReleventDiv("/about", "story")}
                          >
                            Our Story
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0)"
                            onClick={() => jumpToReleventDiv("/about", "leaders")}
                          >
                            Leadership
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="accordionThree">
              <div className="accordion-item">
                <h2 className="accordion-header" id="FooterHeadingThree">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#footerCollapseThree"
                    aria-expanded="true"
                    aria-controls="footerCollapseThree"
                  >
                    <b> Resources</b>
                  </button>
                </h2>
                <div
                  id="footerCollapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="FooterHeadingThree"
                  data-bs-parent="#accordionThree"
                >
                  <div className="accordion-body">
                    <div className="footer-link">
                      <ul>
                        <li>
                          <a
                            href="javascript:void(0)"
                            onClick={() => jumpToReleventDiv("/blog")}
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0)"
                            onClick={() => jumpToReleventDiv("/news")}
                          >
                            News
                          </a>
                        </li>
                        {/* <li>
                          <a
                            href="javascript:void(0)"
                            onClick={() => jumpToReleventDiv("/customer", "casestudies")}
                          >
                            Case Studies
                          </a>
                        </li> */}
                        <li>
                          <a
                            href="javascript:void(0)"
                            onClick={() => jumpToReleventDiv("/research")}
                          >
                            Research
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion" id="accordionFour">
              <div className="accordion-item">
                <h2 className="accordion-header" id="footerHeadingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#footerCollapseFour"
                    aria-expanded="true"
                    aria-controls="footerCollapseFour"
                  >
                    <b> Others</b>
                  </button>
                </h2>
                <div
                  id="footerCollapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="footerHeadingFour"
                  data-bs-parent="#accordionFour"
                >
                  <div className="accordion-body">
                    <div className="footer-link">
                      <ul>
                        <li>
                          <a
                            href="javascript:void(0)"
                            onClick={() => jumpToReleventDiv("/terms-conditions")}
                          >
                            Terms & Conditions
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0)"
                            onClick={() => jumpToReleventDiv("/privacy-policy")}
                          >
                            Privacy Policy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="d-flex">
            <Link to="https://www.facebook.com/tursioai/">
              <img src="image/facebook.svg" alt="Facebook" />
            </Link>
            <Link to="https://x.com/tursioai">
              <img src="image/x.svg" alt="Twitter" />
            </Link>
            <Link to="https://www.linkedin.com/company/tursioai">
              <img src="image/linkedin.svg" alt="LinkedIn" />
            </Link>
          </div>
          <p>2024 Tursio. All Rights Reserved.</p>
        </div>
      </div>
      <PopupModal
        url="https://calendly.com/rony-8gbr/30min"
        prefill={prefill}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        rootElement={document.getElementById("root")}
      />
    </footer>
  );
}

export default Footer;
