import React from 'react';

function PrivacyPolicy() {
    return (
        <section className="privacy-policy">
            <div className="blog-slider-section">
                <div className="theme-title text-center">
                    <h2><span> Privacy Policy</span></h2>
                </div>
            </div>
            <div className="container">
                <div className="privacy-body">
                    <p className="italic">Last Updated: July 18, 2024</p>
                    <p>
                        Tursio Inc., and our affiliated entities (“we”, “us” or the “Company”), respect your privacy and are
                        committed to protecting it through compliance with this Privacy Policy. 
                        This Privacy Policy describes and governs the manner in which we collect, use, maintain and disclose
                        information about you when you use our website, mobile applications and other online services (the
                        “Services”). Please read this Privacy Policy carefully before you start to use the Services. By accessing
                        and/or using the Services, you accept and agree to be bound and abide by this Privacy Policy and to
                        comply with all applicable laws, rules and regulations (collectively, “Applicable Law”). If you do not
                        want to agree to this Privacy Policy, you must not access or use the Services.
                    </p>
                    
                    <h4>Personal Information</h4>
                    <p>
                        We may collect personal information from you in a variety of ways, including the following key
                        categories and types of personal information:
                    </p>
                    <ul className="privacy-list">
                        <li>Contact information: Name, username, address, email address and phone number, and name, jurisdiction of incorporation or formation, address and phone number of company or business entity.</li>
                        <li>Financial information: Credit card and bank and automated clearing house (ACH) information, including account number and routing number and bank name, address and payment reference.</li>
                        <li>Transactional information. If you purchase products or services from us or otherwise through the Services we will collect information about any such transaction, including general transaction details and timing.</li>
                        <li>Third-party information: We collect information shared about you from third-parties that you choose to link to or integrate with our Services.</li>
                        <li>Geolocation data: The general region or area from which you access our Services.</li>
                        <li>Internet or other online activity: Referral channels that led you to our Services, browsing and click history and information about how you navigate our Services.</li>
                        <li>Inferences: We may make inferences based on the information we collect.</li>
                        <li>Information from public sources:  We may obtain information about you on the internet or through offline sources and other public or commercial sources.</li>
                    </ul>

                    <h4>How We Use Collected Information</h4>
                    <p>
                        We may collect and use your personal information for the following purposes:
                    </p>
                    <ul className="privacy-list">
                        <li>To provide and improve customer service. We use your contact information to answer your questions and respond to your requests and inquiries, notify you of changes to the Services, and maintain and improve functionality of our Services.</li>
                        <li>To provide the Services. We use personal information to provide you the Services.</li>
                        <li>For analytical purposes. We use your internet activity, browsing history and geolocation data to analyze preferences, trends and statistics. We may also use your activity on our Services in an anonymized and aggregate way in order to improve our Services. We may also use your information to protect the Company, our Services and our website, and to prevent fraud, theft and misconduct.</li>
                        <li>For relevant updates, marketing and promotional purposes. We use your personal information and various inferences described above to send you relevant articles, blog posts, newsletters, podcasts and other information we think may be relevant to you. We may also use information about your use of our Services in an anonymized and aggregate way to analyze trends and statistics and to promote and market our Services.</li>
                        <li>To comply with the law. We use your personal information to enforce and comply with the law, including to conduct an investigation, to protect the property and rights belonging to us or a third party, to protect the safety of the public or any person, or to prevent or stop activity we may consider to be, or to pose a risk of being, illegal, fraudulent, unethical or legally actionable activity.</li>
                    </ul>

                    <h4>How We Collect Your Personal Information</h4>
                    <p>
                        We collect personal information from the following sources:
                    </p>
                    <ul className="privacy-list">
                        <li>Directly. We collect personal information directly from you. When you register for the Services, submit information in an online form, request information from us, or otherwise communicate with us or our support personnel, you may provide us with information, for example, your name, email address, and phone number.  By providing us with this information, you consent to your information being collected, used, disclosed, processed and stored by us in accordance with this Privacy Policy.</li>
                        <li>Passively. We and our service providers collect personal information from you passively from the internet and other automatic data collection tools, such as cookies and web beacons, which are summarized below:</li>
                        <ul>
                            <li>Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our website.</li>
                            <li>Web Beacons. Pages of our website may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</li>
                            <li>Tracking Tools. We use the following tracking tools:</li>
                            <ul>
                                <li>Google Analytics and Adwords: We use Google tools such as Google Analytics and Google Adwords to understand how users use our website. This includes looking at where a visitor comes from and where they go when they leave our site. Google Analytics provides an opt-out for its tool here.</li>
                                <li>Other tracking tools: We may deploy other automatic tracking tools in the future. Similar to the tools we currently use, such future tracking tools will track data about our visitors only in the aggregate and on an anonymized basis and will not reveal personal information about you.</li>
                            </ul>
                        </ul>
                    </ul>

                    <h4>When We Share Your Personal Information with Third Parties</h4>
                    <ul className="privacy-list">
                        <li>With service providers who do things on our behalf. For example, we will share information with vendors and contractors who conduct email marketing for us or who assist us with developing and providing the Services and customer support. Such service providers may reside outside the United States and in countries that may not provide the same level of protections as the laws of the United States.</li>
                        <li>With third parties as directed by you or to support transactions you engage in via the Services. We may share your information with third parties with your consent or as requested by you, or in connection with a transaction you engage in through the Services.</li>
                        <li>In accordance with applicable law. We may share your information with the appropriate authorities if we believe disclosure is in accordance with, or required by, any applicable law, including lawful requests by public authorities to meet national security or law enforcement requirements.</li>
                        <li>In connection with sale of the Company. In the event of a reorganization, merger, or sale we may transfer your personal information we collect to the relevant third party.</li>
                    </ul>

                    <h4>We Do Not Sell Your Personal Information</h4>
                    <p>We do not sell, trade, or rent your personal information to others.</p>

                    <h4>Third-Party Links and Websites</h4>
                    <p>
                        Our Services may contain advertising, links or other content from the websites and services of our
                        partners, suppliers, advertisers, sponsors, licensors and other third parties (collectively, the “Third-Party
                        Services”). We do not control the content or links that appear on these Third-Party Services and are not
                        responsible for the practices employed by such Third-Party Services. In addition, these Third-Party
                        Services may have their own privacy policies and customer service policies. Browsing and interacting on
                        any of these Third-Party Services are subject to such Third-Party Services’ own terms and policies.
                    </p>

                    <h4>Our Content on Third-Party Services</h4>
                    <p>
                        Our Services may be provided on or hosted on a third-party platform or otherwise make use of Third-
                        Party Services. We do not control the content or links that appear on these Third-Party Services and are
                        not responsible for the practices employed by such Third-Party Services. Your browsing and interacting
                        on any of these Third-Party Services are subject to such Third-Party Services’ own terms and policies.
                        Any visit you make to those other services is at your own risk. Such third parties’ use of any information
                        you share is governed by the third party’s privacy policy.  The Services may also use third-party service
                        remarketing services to advertise to previous visitors to the Services on such Third-Party Services. Such
                        third parties may use tracking technology (e.g., cookies) to serve ads to you based on your past activity on
                        our Services and other websites and applications, subject to their own privacy policies.
                    </p>

                    <h4>Your Choices</h4>
                    <p>
                        You have certain choices on how we treat your personal information, described below:
                    </p>
                    <ul className="privacy-list">
                        <li>Modifications to Personal Information. You may review and request modifications to your personal information by editing your profile directly on our website or by contacting us at contact@tursio.ai. Please note that we will retain data for as long as it is reasonably necessary to fulfill the purpose for which it has been collected or as required or permitted by applicable law. If you provide us with updated information, we will do our best to keep your information accurate and up-to-date. We will make good-faith efforts to make requested changes in any active database as soon as practicable.</li>
                        <li>Marketing Communications. You can opt out of promotional marketing communications by unsubscribing through the Services or contacting us at contact@tursio.ai. We may give choices about other emails and communications you receive from us. If you opt out, we may still send you non-promotional communications, such as those about your account or our ongoing business.</li>
                        <li>Ad Choices. Some advertisements and other content may be delivered by third-party advertising networks and advertisers that may use cookies and similar and/or your advertiser ID for mobile devices to collect and track information such as demographic information, inferred interests, aggregated information, and activity to assist them in delivering advertising that is more relevant to your interests. To find out more about third-party advertising networks and similar entities that use these technologies, see www.aboutads.info. If you would like to opt-out of such ad networks’ and services’ advertising practices, you may find a service provider (for example, www.aboutads.info/choices) to opt out in desktop and mobile web browsers.</li>
                        <li>Tracking Technology. You may turn off part or all of our tracking software that has been placed on your computer by following the instructions on your browser. On a mobile device, you may turn off part or all of mobile tracking through your mobile device settings. However, if you prevent the use of tracking software or tracking through your mobile device, it will be more difficult, and may be impossible, for you to use the Service or portions of the Services.</li>
                        <li>Location and Analytics. If we use Third-Party Services to automatically collect your personal information, such Third-Party Services may offer you a choice to stop the automatic collection of your information. Please see our “How we collect your personal information” above with respect to our tracking tools.</li>
                        <li>Cookies. Most web browsers are set by default to accept cookies. You can usually set your browser to remove or reject cookies. Please note if you choose to reject, this could affect the availability and functionality of our Services.</li>
                        <li>Not Providing Personal Information. You may choose not to provide personal information to us. However, if you do not provide personal information, we may not be able to offer you all or part of our Services.</li>
                    </ul>

                    <h4>Where Your Information Is Stored</h4>
                    <p>
                        We store your information in the United States. However, we may transfer your information to our service
                        providers who may reside in other countries.
                    </p>
                    <p>
                        By using the Services or otherwise providing information to us, you understand and consent to having any
                        personal information transferred to and processed in the United States. If you reside in the European
                        Union (the “EU”), we will transfer your personal information pursuant to EU data protection laws. You
                        understand that the United States may not provide the same level of protections as the laws in your
                        country. United States data protection and other relevant laws may not be the same as those in your
                        jurisdiction. In certain circumstances law enforcement or regulatory agencies, courts or security
                        authorities in the United States may be entitled to access your personal information.
                    </p>

                    <h4>Information Security</h4>
                    <p>
                        We use commercially reasonable security technologies and procedures to help protect your personal
                        information from unauthorized access, use or disclosure. However, we cannot guarantee the complete
                        safety of your information. It is your responsibility to keep your information confidential.
                    </p>

                    <h4>How Long We Keep Your Information</h4>
                    <p>
                        We retain, store and use your information for the least amount of time necessary for our relationship with you
                        and to provide you access to our Services and in accordance with data retention policies and applicable
                        law.
                    </p>

                    <h4>Our Services Are Not Intended For Children</h4>
                    <p>
                        Our Services are not directed to children under the age of 18. We do not knowingly collect information,
                        including personal information, from children. If we obtain actual knowledge that we have collected such
                        information from children, then we will promptly delete it. If you believe we have mistakenly collected
                        information from children listed above, please contact us at contact@tursio.ai.
                    </p>

                    <h4>Information for European Economic Area Residents</h4>
                    <p>
                        If you are a resident of the European Economic Area (“EEA”), you have certain rights and protections
                        under applicable law regarding the processing of your personal information. The term “personal
                        information” has the meaning given to it by the European General Data Protection Regulation (“GDPR”).
                        When we process your personal information as described in this Privacy Policy, we will only do so when
                        we have a legitimate interest in processing your personal information (for example, our legitimate interest
                        in providing the Services, responding to your inquiries, or sending you marketing communications), when
                        the processing is necessary for the performance of a contract between you and us, when the processing is
                        necessary for us to comply with the law, or when you give us consent to process your personal
                        information. You have the right to revoke your consent at any time. You also have the right to access
                        personal information we hold about you and to ask that your personal information be corrected, erased, or
                        transferred. You may also have the right to object to, or request that we restrict, certain processing. If you
                        would like to exercise any of these rights, you may contact us as indicated below. If you have a concern
                        about our processing of personal information that we are not able to resolve, you have the right to lodge a
                        complaint with the data privacy authority where you reside. 
                    </p>
                    <p>
                        For contact details of your local Data Protection Authority, please see: <a href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm">here</a>.
                    </p>

                    <h4>"Do Not Track" Policy as Required by California Online Privacy Protection Act (“CalOPPA”)</h4>
                    <p>
                        Our Service does not respond to Do Not Track (“DNT”) signals. However, some third-party websites do
                        keep track of your browsing activities. If you are visiting such websites, you can set your preferences in
                        your web browser to inform websites that you do not want to be tracked. You can enable or disable DNT
                        by visiting the preferences or settings page of your web browser.
                    </p>

                    <h4>Your California Privacy Rights</h4>
                    <p>
                        If you are a California resident, California's "Shine the Light" law (Civil Code Section § 1798.83) permits
                        users of our Services to request certain information regarding our disclosure of personal information to
                        third parties for their direct marketing purposes and to opt-out of sharing your personal information with
                        such third parties. To make such a request, please send an email to contact@tursio.ai.
                    </p>

                    <h4>Usage and Deletion of Personal Information</h4>
                    <p>
                        You may request what personal information we have collected, used and disclosed about you as well as
                        the identity of the third parties to which we have disclosed your personal information. You may also
                        request deletion of your personal information. Please note that we may not delete all of your information
                        if: (1) we need it to complete a service as requested by you or perform under a contract we have with you;
                        (2) we need such information to repair any errors to our Services or detect data security violations; or
                        (3) we need such information to protect against fraud or illegal activity or to comply with applicable law.
                        Please note that if we delete your personal information, we may not be able to provide you the Services
                        with the same functionality.
                    </p>
                    <p>
                        To make any request for personal information or deletion, please send an email to contact@tursio.ai.
                    </p>

                    <h4>Changes to this Privacy Policy</h4>
                    <p>
                        We have the discretion to update this Privacy Policy at any time. We encourage you to frequently check
                        this page for any changes. You acknowledge and agree that it is your responsibility to review this Privacy
                        Policy periodically and become aware of modifications. Your continued use of our Services shall be
                        deemed an acceptance of our revised policy.
                    </p>

                    <h4>You Can Contact Us</h4>
                    <p>
                        If you have any questions about this Privacy Policy, you can email us at contact@tursio.ai.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default PrivacyPolicy;
