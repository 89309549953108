import { useNavigate } from "react-router-dom";
import homeSectionData from "../../assets/json/homePageData.json";
import Common from "../Common";
import { useEffect } from "react";

function Home() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/contact');
    };

    useEffect(() => {
        const modal = document.getElementById('exampleModalToggle');
        const videoIframe = modal.querySelector('iframe');

        const handleModalHide = () => {
            videoIframe.src = '';
        };

        modal.addEventListener('hide.bs.modal', handleModalHide);

        return () => {
            modal.removeEventListener('hide.bs.modal', handleModalHide);
        };
    }, []);

    return (
        <div>
            <Common />
            <section className="home-slider">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
                            <div className="header-theme-title h-100">
                                <h1>
                                    {homeSectionData?.HeroSection?.title}
                                    <span>{homeSectionData?.HeroSection?.subTitle}</span>
                                </h1>
                                <p>{homeSectionData?.HeroSection?.content}</p>
                                <div className="d-inline-block w-100 mt-4">
                                    <button type="button" className="btn btn-primary me-2" onClick={handleClick}>
                                        Request demo
                                    </button>
                                    {/* <button type="button" className="btn btn-outline-dark me-2">
                                        Read case studies
                                    </button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="embed-responsive">
                                <iframe
                                    src={homeSectionData?.HeroSection?.videoLink}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="slider-optimized-content">
                        {homeSectionData.HeroSection.logos.map((ele, index) => {
                            return (
                                <div className="item-content" key={index}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/image/${ele.link}.svg`}
                                        alt=""
                                    />
                                    <p>{ele.title}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            <section className="company-logo">
                <div className="container">
                    <div className="swiper companyLogo">
                        <div className="swiper-wrapper">
                            {/* {homeSectionData?.HeroSection?.logoSwiper.map((logo, ind) => {
                                return (
                                    <div className="swiper-slide" key={ind}>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/image/${logo.logourl}.png`}
                                            alt=""
                                            key={ind}
                                        />
                                    </div>
                                );
                            })} */}
                        </div>
                    </div>
                </div>
            </section>

            <section className="enterprises-database" id="product-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                            <div className="theme-title">
                                <h2>
                                    {homeSectionData?.EnterprisesSection?.titleOne}{" "}
                                    <span> {homeSectionData?.EnterprisesSection.titleTwo}</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 leptop-layout">
                            <div className="enterprises-body">
                                {homeSectionData?.EnterprisesSection.boxImages.map(
                                    (ele, ind) => {
                                        return (
                                            <img
                                                id={ele.imageClass}
                                                className={ele.imageClass}
                                                src={`${process.env.PUBLIC_URL}/image/${ele.imageFileName}.svg`}
                                                alt=""
                                                key={ind}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="machines-card">
                        <div className="row justify-content-center">
                            {homeSectionData.EnterprisesSection.cardsData.map(
                                (card, index) => {
                                    return (
                                        <div
                                            className="col-lg-4 col-md-6 col-sm-12 mb-3"
                                            key={index}
                                        >
                                            <div className="enterprises-card">
                                                <img
                                                    className="icon"
                                                    src={`${process.env.PUBLIC_URL}/image/${card.imageFileName}.svg`}
                                                    alt="Specialized"
                                                />
                                                <h3>{card.title}</h3>
                                                <p>{card.content}</p>
                                                <ul>
                                                    {card.specifications.map((specification, ind) => {
                                                        return (
                                                            <li key={ind}>
                                                                <img
                                                                    src={`${process.env.PUBLIC_URL}/image/${specification.imageFileName}.svg`}
                                                                    alt=""

                                                                />
                                                                {specification.title}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <section className="database-workload-start">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="workload-path">
                            <div>
                                <img
                                    className="Workload-path"
                                    src={`${process.env.PUBLIC_URL}/image/${homeSectionData.databaseWorkload?.databaseWorkloadSectionImageFileName}.svg`}
                                    alt=""
                                />
                            </div>
                            <span></span>
                        </div>
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="theme-title text-center">
                                <h2>
                                    {homeSectionData?.databaseWorkload?.title}{" "}
                                    <span> {homeSectionData?.databaseWorkload?.subTitle}</span>
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div id="DatabaseWorkload" className="row mt-4 justify-content-between align-items-center database-card-row">
                        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 ">
                            <div className="database-image mb-4">
                                {homeSectionData?.databaseWorkload?.databaseImages?.map(
                                    (ele, ind) => {
                                        return (

                                            <img
                                                id={ele.fileName}
                                                className={ele.className}
                                                src={`${process.env.PUBLIC_URL}/image/${ele.fileName}.svg`}
                                                alt=""
                                                key={ind}
                                            />
                                        );
                                    }
                                )}
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
                            {homeSectionData?.databaseWorkload?.databaseData?.map(
                                (ele, ind) => {
                                    return (
                                        <div className="database-stap" key={ind}>
                                            <span>{ele.id}</span>
                                            <div className="content">
                                                <h4>{ele.title}</h4>
                                                <p>{ele.content}</p>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                            <div className="watch-btn">
                                <a
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    href="#exampleModalToggle"
                                    role="button"
                                >
                                    {homeSectionData?.databaseWorkload?.buttonData?.buttonContent}
                                    <img
                                        src={`${process.env.PUBLIC_URL}/image/${homeSectionData?.databaseWorkload?.buttonData?.buttonImageFileName}.svg`}
                                        className="ms-2"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div
                className="modal fade"
                id="exampleModalToggle"
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <button
                                type="button"
                                className="model-btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <img src="image/close.svg" alt=""/>
                            </button>
                            <div className="embed-responsive">
                                <iframe
                                    src={homeSectionData?.HeroSection?.videoLink}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="no-brainer-section" id="no-brainer-section">
                <div className="container">
                    <div className="theme-title text-center">
                        <h2>
                            {homeSectionData?.NoBrainer?.title}
                            <span> {homeSectionData?.NoBrainer?.subTitle}</span>
                        </h2>
                    </div>
                    <div className="row">
                        {homeSectionData?.NoBrainer?.noBrainerData?.map((ele, ind) => {
                            return (
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3" key={ind}>
                                    <div className="brainer-card">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/image/${ele.imageFileName}.svg`}
                                            alt=""
                                        />
                                        <h3>{ele?.title}</h3>
                                        <h6>{ele.content}</h6>
                                        <p>{ele.description}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            <section className="try-out-automated-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12">
                            <div className="theme-title">
                                <h2>
                                    <span>{homeSectionData?.AutomatedGenAI?.title}</span>{" "}
                                    {homeSectionData?.AutomatedGenAI?.subTitle}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {homeSectionData?.AutomatedGenAI?.AutomatedGenAIData?.map(
                            (ele, ind) => {
                                return (
                                    <div
                                        className="col-lg-4 col-md-6 col-sm-12 mb-3"
                                        index="index"
                                        key={ind}
                                    >
                                        <div className="no-brainer-card">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/image/${ele?.imageFileData?.fileName}.svg`}
                                                alt={ele?.imageFileData?.content}
                                            />
                                            <h3>
                                                {ele.title}{" "}
                                                <span className="mobile-block"> {ele.subTitle}</span>
                                            </h3>
                                            <p className="desktop-show">{ele?.content}</p>
                                            <p className="mobile-show">{ele?.mobileViewContent}</p>
                                        </div>
                                    </div>
                                );
                            }
                        )}
                    </div>
                </div>
            </section>

            <div className="database-naturally-section">
                <div className="container">
                    <div className="database-bg">
                        <div className="row d-flex justify-content-end align-items-center">
                            <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
                                <div className="theme-title position-relative">
                                    <h2 className="text-white">
                                        {homeSectionData?.databaseNaturally?.title}{" "}
                                        <span> {homeSectionData?.databaseNaturally?.subTitle}</span>
                                    </h2>
                                    <p className="text-white">
                                        {homeSectionData?.databaseNaturally?.content}
                                    </p>
                                    <div className="mt-5">
                                        <button type="button" className="btn btn-primary me-2" onClick={handleClick}>
                                            {homeSectionData?.databaseNaturally?.requestDemoBtn}
                                        </button>
                                        {/* <button
                                            type="button"
                                            className="btn mobile-block btn-outline-light"
                                        >
                                            {homeSectionData?.databaseNaturally?.readCaseStudiesBtn}
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="customer-feedback-section">
                <div className="container">
                    <div className="theme-title">
                        <h2>
                            {homeSectionData?.CustomerFeedback?.title}{" "}
                            <span>{homeSectionData?.CustomerFeedback?.subTitle}</span>
                        </h2>
                    </div>
                    <div className="swiper customerFeedback">
                        <div className="swiper-wrapper">
                            {homeSectionData?.CustomerFeedback?.CustomerCard.map(
                                (ele, ind) => {
                                    return (
                                        <div className="swiper-slide" key={ind}>
                                            <div className="customer-feedback-card">
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/image/${ele?.startImageFileName}.svg`}
                                                    alt=""
                                                />
                                                <span className="feedback-title">{ele?.title}</span>
                                                <p>{ele?.subTitle}</p>
                                                <div className="customer-user">
                                                    <div className="me-3">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/image/${ele?.profileImageFileName}.jpg`}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div>
                                                        <h4>{ele?.CustomerName}</h4>
                                                        <span>{ele?.CustomerContant}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <section className="customer-scenarios-section">
                <div className="container position-relative">
                    <div className="row justify-content-between">
                        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 mb-4">
                            <div className="theme-title">
                                <h2>
                                    {homeSectionData?.CustomerScenarios?.title}{" "}
                                    <span> {homeSectionData?.CustomerScenarios?.subTitle}</span>
                                </h2>
                                <p>{homeSectionData?.CustomerScenarios?.content}</p>
                                <button type="button" className="btn btn-primary me-2" onClick={handleClick}>
                                    {homeSectionData?.CustomerScenarios?.requestDemoBtn}
                                </button>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
                            {homeSectionData?.CustomerScenarios?.CustomerCard?.map(
                                (ele, index) => {
                                    return (
                                        <div className="customer-card mb-3" key={index}>
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <h3>{ele?.title}</h3>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/image/${ele?.imageFileName}.svg`}
                                                    alt=""
                                                />
                                            </div>
                                            <p>{ele?.content}</p>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
                <div className="c-animeted-grfics">
                    <img id="customer-one" className="customer-scenarios-cube-one"
                         src='image/customer-scenarios-cube-one.svg' alt="icon"/>
                    <img id="customer-tow" className="customer-scenarios-cube-tow"
                         src='image/customer-scenarios-cube-tow.svg' alt="icon"/>
                </div>
            </section> */}

            <section className="faq-section">
                <div className="container">

                    <div className="theme-title text-center">
                        <h2>
                            {homeSectionData?.FAQ?.title}<span>’</span>{homeSectionData?.FAQ?.subTitle}
                        </h2>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-8 col-md-12">
                            {
                                homeSectionData?.FAQ?.FaqContent?.map((ele, ind) => {
                                    return (
                                        <div className="accordion accordion-flush" id={ele.id} key={ind}>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id={ele?.headingId}>
                                                    <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#${ele?.buttonId}`} aria-expanded="false"
                                                            aria-controls={`${ele?.buttonId}`}>
                                                        {ele?.title}
                                                        <div className="add-remove-icon">
                                                            <img className="add-icon"
                                                                 src={`${process.env.PUBLIC_URL}/image/${ele?.addIconFileName}.svg`}
                                                                 alt=""/>
                                                            <img className="remove-icon"
                                                                 src={`${process.env.PUBLIC_URL}/image/${ele?.removeIconFileName}.svg`}
                                                                 alt=""/>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div id={`${ele?.buttonId}`} className="accordion-collapse collapse"
                                                     aria-labelledby={`#${ele?.headingId}`}
                                                     data-bs-parent={`#${ele?.id}`}>
                                                    <div className="accordion-body">
                                                        <p>{ele?.content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>

                </div>
            </section>

            <section className="questions-section">
                <div className="container">
                    <div className="questions-card">
                        <div className="row justify-content-between align-items-center position-relative">
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                <div className="content">
                                    <div className="me-4 unlimited-cube-container">
                                        {/*<img src={`${process.env.PUBLIC_URL}/image/${homeSectionData?.UnlimitedUser?.imageFileName}.svg`}*/}
                                        {/*     alt="icon"*/}
                                        {/*/>*/}
                                        <img id="hexagonBlue" className="u-dubel-cube cube-img-one" src="image/unlimited-cube-singel.svg" alt="icon"/>
                                        <img  id="hexagonWhite"  className="u-dubel-cube" src="image/unlimited-cube.svg" alt="icon"/>
                                    </div>
                                    <div>
                                        <div className="theme-title">
                                            <h2 className="text-white">
                                                <span> {homeSectionData?.UnlimitedUser?.title}</span>
                                                <br/>
                                                {homeSectionData?.UnlimitedUser?.subTitle}
                                            </h2>
                                            <p className="mb-0 mt-2 text-white">
                                                {homeSectionData?.UnlimitedUser?.content}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="questions-btn">
                                    <button type="button" className="btn btn-outline-light ms-3" onClick={handleClick}>
                                        {homeSectionData?.UnlimitedUser?.requestDemoBtn}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
